import fetch from 'auth/FetchInterceptor';

const authService = {};

authService.login = function (params) {
  console.log('params : ', params);
  return fetch({
    url: '/api/auth/login',
    method: 'post',
    data: params,
    headers: {
      'public-request': 'true'
    }
  });
};

export default authService;
