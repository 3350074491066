import React, { useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import notificationData from 'assets/data/notification.data.json';
import Flex from 'components/shared-components/Flex';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import Logo from 'assets/loader.png';
export const NavNotification = () => {
  const user = localStorage.getItem('AUTH_USER');
  const _user = JSON.parse(user);
  return (
    <div>
      <div className={`d-flex align-items-center justify-content-between mb-4`}>
        <AvatarStatus src={Logo} name={_user?.name} />
      </div>
    </div>
  );
};

export default NavNotification;
