import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import { showAuthMessage, authenticated, signOutSuccess, signUpSuccess } from '../actions/Auth';

import FirebaseService from 'services/FirebaseService';
import authService from 'services/AuthService';

{
  /*export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(FirebaseService.signInEmailRequest, email, password);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}
*/
}
export function* singInEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    console.log('payload : ', payload);
    const params = {
      email: email,
      password: password
    };
    try {
      const user = yield call(authService.login, params);
      const { message, accessToken } = user;
      console.log('user : ', user);
      if (message) {
        yield put(showAuthMessage(message));
      } else {
        localStorage.setItem(AUTH_TOKEN, accessToken);
        localStorage.setItem('AUTH_USER', JSON.stringify(user));

        yield put(authenticated(accessToken, user));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(FirebaseService.signUpEmailRequest, email, password);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(singInEmail), fork(signOut), fork(signUpWithFBEmail)]);
}
