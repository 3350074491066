import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/'
  },

  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/'
  },
  {
    title: 'Billing',
    icon: ShopOutlined,
    path: '/'
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/'
  }
];

export const NavProfile = ({ signOut }) => {
  const profileImg = '/img/avatars/thumb-1.jpg';
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-body'>
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
        <Menu className='d-flex align-item-center' mode='horizontal'>
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </Dropdown>
    </>
  );
};

export default connect(null, { signOut })(NavProfile);
