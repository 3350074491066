const dev = {
  API_ENDPOINT_URL: 'https://api.eryagroups.com'
};

const prod = {
  API_ENDPOINT_URL: 'https://api.eryagroups.com'
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com'
};
console.log("process.env.NODE_ENV :   " , process.env.NODE_ENV)
const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
